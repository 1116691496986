import axios from 'axios'

// this is the API used to handle user logins and registrations
const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})

export default api

export const AXIOS = axios