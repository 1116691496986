import './App.css';
import './Styles/rfc-theme.css'

import { BrowserRouter as Router } from 'react-router-dom'
import LayoutView from './LayoutView';


function App() {
  return (
    <Router>
      <div className="App">
        <LayoutView />
      </div>
    </Router>
  );
}

export default App;
