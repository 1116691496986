import React from 'react';
import './ResultsTable.css';

const ResultsTable = (props) => {
    const jackPotValue = props.jackpotValue;

    const data = [];
    for (let i = 4; i < 7; i++) {
        let prize;
        if (i === 4) {
            prize = parseFloat(jackPotValue * 9.09 / 100).toFixed(2);
        } else if (i === 5) {
            prize = parseFloat(jackPotValue * 18.18 / 100).toFixed(2);
        } else if (i === 6) {
            prize = parseFloat(jackPotValue * 72.72 / 100).toFixed(2);
        }

        data.push({
            key: i,
            result: `${i} Match`,
            prize: `${prize} USDT`,
        });
    }

    return (
        <div>
            {/* <div className="table-row table-header">
                <div className="table-cell">Result</div>
                <div className="table-cell">Prize allocations</div>
            </div> */}
            {data.map((item, index) => (
                <div
                    className={`table-row ${index % 2 === 1 ? 'table-row-light' : 'table-row-dark'}`}
                    key={item.key}
                >
                    <div className="table-cell">{item.result}</div>
                    <div className="table-cell1">{item.prize}</div>
                </div>
            ))}
        </div>
    );
};

export default ResultsTable;
