import React, { useEffect, useState } from 'react';
import { Card, Button, message } from 'antd';
import './ClaimCards.css';
import { readContract, writeContract } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { configs } from '../../../Blockchain/web3.config';
import { Row } from 'reactstrap';
import { ethers } from 'ethers';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 16,
        }}
        spin
    />
);

function TicketsCard(props) {
    const { address, isConnected } = useAccount();
    const userAddress = address;
    const roundIdValue = props.roundIdNo;
    const [currentDrawTickets, setCurrentDrawTickets] = useState([]);
    const [allRoundsTickets, setAllRoundsTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [winnersList, setWinnersList] = useState([]);
    // const [error, setError] = useState('');

    const winnerDetails = props.winnerDetails;
    const ticketIds = winnerDetails.map((winner) => parseFloat(winner.ticketId));
    const roundIds = winnerDetails.map((winner) => parseFloat(winner.roundId));

    // console.log('ticketIds:', ticketIds);
    // console.log('roundIds:', roundIds);

    const winnings = winnerDetails.reduce((sum, winner) => sum + parseFloat(winner.winningAmount), 0);

    useEffect(() => {
        if (isConnected) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const data = await readContract({
                        address: configs.superlottoContractAddress,
                        abi: configs.superlottoContractABI,
                        functionName: 'getUserTickets',
                        args: [roundIdValue, userAddress],
                    });
                    // console.log('getUserTickets data:', data.toString()); // Check the data being returned

                    if (data && data.length > 0) {
                        const userTicketId = data.map(item => parseFloat(item.toString()));
                        setCurrentDrawTickets(userTicketId);
                    } else {
                        setCurrentDrawTickets([]);
                    }

                    setLoading(false);
                } catch (error) {
                    // console.error('Error fetching user tickets:', error);
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [isConnected, roundIdValue, userAddress]);

    useEffect(() => {
        const fetchRecentRounds = async () => {
            try {
                setLoading(true);
                const roundIds = [];
                for (let i = roundIdValue; i >= 0; i--) {
                    const data = await readContract({
                        address: configs.superlottoContractAddress,
                        abi: configs.superlottoContractABI,
                        functionName: 'getUserTickets',
                        args: [i, userAddress],
                    });
                    roundIds.push({
                        roundId: i,
                        tickets: data,
                    });
                }
                setAllRoundsTickets(roundIds);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching all rounds tickets:', error);
                setLoading(false);
            }
        };

        fetchRecentRounds();
    }, [roundIdValue, userAddress]);

    const currentRoundTickets = currentDrawTickets.length;

    const allRoundsTicketsCount = allRoundsTickets.reduce((count, round) => {
        if (round.roundId === roundIdValue) {
            // Add current round's tickets to the count
            return count + currentRoundTickets;
        } else {
            // Add tickets from previous rounds
            return count + (round.tickets ? round.tickets.length : 0);
        }
    }, 0);

    useEffect(() => {
        if (isConnected) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const data = await readContract({
                        address: configs.superlottoContractAddress,
                        abi: configs.superlottoContractABI,
                        functionName: 'getUserTickets',
                        args: [roundIdValue, userAddress],
                    });
                    // console.log('getUserTickets data:', data.toString()); // Check the data being returned

                    if (data && data.length > 0) {
                        const userTicketId = data.map(item => parseFloat(item.toString()));
                        setCurrentDrawTickets(userTicketId);
                    } else {
                        setCurrentDrawTickets([]);
                    }

                    setLoading(false);
                } catch (error) {
                    // console.error('Error fetching user tickets:', error);
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [isConnected, roundIdValue, userAddress]);

    const claimPrizeHandler = async (roundId, ticketId) => {
        // console.log('both roundId:', roundId, 'ticketId:', ticketId);
        try {
            const { hash } = await writeContract({
                address: configs.superlottoContractAddress,
                abi: configs.superlottoContractABI,
                functionName: 'claimPrize',
                args: [roundId, ticketId],

            });
            // Handle the response or further actions
            if (hash) {
                message.success('Successfully claim your prize!');
            }
        } catch (error) {
            // Handle the error
            message.error('Failed to claim your prize!', error);
        }
    };

    // Pass ticketIds and roundIds to claimPrizeHandler
    const handleClaimPrize = (ticketIds, roundIds) => {
        // Assuming ticketIds and roundIds have the same length
        for (let i = 0; i < ticketIds.length; i++) {
            claimPrizeHandler(roundIds[i], ticketIds[i]);
        }
    };


    return (
        <Card className='fathom-finance-card claim-card mt-4 mb-2 text-center' >
            <div className="claim-card-headline ticket-bottom">Tickets</div>
            <div className="bottom-line mt-2 mb-3" />
            <div className="mt-4">
                <div className="claim-card-content p-2">
                    <p className="sub-text">Your total number of tickets</p>
                    {/* {allRoundsTicketsCount >= 0 && roundIdValue != 1 ? <p className="sub-text2">{allRoundsTicketsCount}</p> : <Spin indicator={antIcon} />} */}
                    {loading ? <Spin indicator={antIcon} /> : <p className="sub-text2">{allRoundsTicketsCount}</p>}
                </div>
                <div className="claim-card-content p-2">
                    <p className="sub-text">Your tickets in this round</p>
                    {/* {currentRoundTickets >= 0 && allRoundsTicketsCount >= 0 && roundIdValue != 1 ? <p className="sub-text2">{currentRoundTickets}</p> : <Spin indicator={antIcon} />} */}
                    {loading ? <Spin indicator={antIcon} /> : <p className="sub-text2">{currentRoundTickets}</p>}
                </div>
                <div className="claim-card-content p-2">
                    <p className="sub-text">Winnings</p>
                    <p className="sub-text2">{ethers.utils.formatUnits(winnings.toFixed())} USDT</p>
                </div>
                <div className="claim-card-content1 hide-on-mobile margin-b">
                    {/* <div className='m-1 '>
                        <WalletConnect />
                    </div> */}

                    <Button
                        className='claim-card-button1 margin-b m-1'
                        type='primary'
                        disabled={winnings === 0}
                        onClick={() => handleClaimPrize(ticketIds, roundIds)}
                    >
                        Claim Winning
                    </Button>
                </div>
                <div className="hide-on-pc mt-2">
                    {/* <p className="sub-text">Winnings</p>
                    <p className="sub-text2">0 BUSD</p> */}
                    {/* <Row className='mx-auto mt-2'>
                        <div className=' claim-card-button2' >
                            <WalletConnect />
                        </div>
                    </Row> */}
                    <Row className='claim-card-button2 mt-3'>
                        <Button
                            style={{ width: '140px' }}
                            className='claim-card-button1'
                            type='primary'
                            disabled={winnings === 0}
                            onClick={() => handleClaimPrize(ticketIds, roundIds)}
                        >
                            Claim Winning
                        </Button>
                    </Row>
                </div>
            </div>
        </Card>
    );
}

export default TicketsCard;
