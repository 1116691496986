import { Card } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom'
import './TokensListPage/TokenListPage.css';

function NotFound() {

    return (
        <div className='mx-auto mt-5'>
            <div className='admin-tab-align'>
                <div className='mt-5 mb-5'>
                    <Card className='not-found-card mt-5'>
                        <div className='mt-5 hide-on-mobile'>
                            <h2>404 - Page not found</h2>
                            <p>
                                The page you are looking for does not exist. <Link to="/">Go to Home</Link>
                            </p>
                        </div>
                        <div className='hide-on-pc'>
                            <h2>404 - Page not found</h2>
                            <p>
                                The page you are looking for does not exist. <Link to="/">Go to Home</Link>
                            </p>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default NotFound;