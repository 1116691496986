import React, { useState, useEffect } from 'react'
import fathomsl from '../../../images/box-image.png'
import { Card } from 'antd'
import { Col, Row } from 'reactstrap'
import './JackpotValueCard.css'
// import { useContractRead, useAccount } from 'wagmi';
// import { configs } from '../../../Blockchain/web3.config'


function JackpotValueCard(props) {

    // const [jackPotValue1, setJackpotValue1] = useState()

    // const { data: jackPotValue } = useContractRead({
    //     address: configs.superlottoContractAddress,
    //     abi: configs.superlottoContractABI,
    //     functionName: 'jackPotValue'
    // });

    // useEffect(() => {
    //     if (jackPotValue) {
    //         setJackpotValue1(jackPotValue.toString());
    //         console.log('jackPot', jackPotValue);
    //     }
    //     console.log('jackpotvalue', jackPotValue1);

    // },
    //     [jackPotValue, jackPotValue1]
    // );

    return (
        <>
            {/* <Card className='fathom-finance-card mt-4 mb-2'> */}
            <Card className='fathom-finance-card jackpot-value-card mt-4 mb-2' >
                <Row>
                    <Col xxl="7" xl="7" lg="7" md="7" sm="7" xs="7">
                        <Row className='mt-5 hide-on-mobile'>
                            <div className='jackpot-headline'>Total Prize Value</div>
                            <div className='jackPotValue-style'>{props.jackPotValue} USDT</div>
                        </Row>
                        <Row className='mt-3 hide-on-pc'>
                            <div className='jackpot-headline'>Total Prize Value</div>
                            <div className='jackPotValue-style'>{props.jackPotValue} USDT</div>
                        </Row>
                        {/* <Row className='mt-4'> */}
                        {/* <Col xxl="5" xl="5" lg="5" md="5" sm="5" xs="5">
                                <p className='subtext-style'>$118 475.25</p>
                                <p className='subtext2-style'>Prize from tickets</p>
                            </Col>
                            <Col xxl="1" xl="1" lg="1" md="1" sm="1" xs="1">
                                <p className='subtext-plus-style'>+</p>
                            </Col>
                            <Col xxl="5" xl="5" lg="5" md="5" sm="5" xs="5">
                                <p className='subtext-style'>$91 214.38</p>
                                <p className='subtext2-style'>BAYC #9419</p>
                            </Col> */}
                        {/* </Row> */}
                    </Col>
                    <Col xxl="5" xl="5" lg="5" md="5" sm="5" xs="5">
                        <img className='card-icon' src={fathomsl} alt='Service Icon' />
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default JackpotValueCard