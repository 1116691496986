import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Avatar, Card, Spin, Checkbox, Button, message, notification, } from 'antd';
import { Col, Row } from 'reactstrap';
import { readContract } from '@wagmi/core';
import imageUrl from '../../../images/ticket-bg.png';
import imageUrl2 from '../../../images/ticket-bg.svg';
import { CloseOutlined } from '@ant-design/icons';
import './PlayNow.css';
import { useContractWrite, useContractRead, usePrepareContractWrite } from "wagmi";
import { configs, buyingTokenConfigs } from '../../../Blockchain/web3.config';
import { utils } from 'ethers';

const PreviousNumbers = (props) => {
  const [allTicketsValue, setAllTicketsValue] = useState([]);
  const [ticketNumbers, setTicketNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { address, isConnected } = useAccount();
  const userAddress = address;
  const roundIdValue = props.roundIdValue - 1;
  const [previousTickets, setPreviousTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [isTokenApproved, setIsTokenApproved] = useState(false);
  const [selectedTicketCount, setSelectedTicketCount] = useState(0);
  const ticketPrize = props.ticketPrize;
  const [ticketPrice, setTicketPrice] = useState(0);
  const ticketPrizeValue = utils.parseEther(ticketPrice.toString());
  const [initialNumbers, setInitialNumbers] = useState([]);
  const [buyingTokenAddress, setBuyingTokenAddress] = useState('');
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [haveDuplicate, setHaveDuplicate] = useState(false)
  // const [duplicatesErrorMessage, setDuplicatesErrorMessage] = useState('')
  // const [isCanProceed, setIsCanProceed] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledCheckBox, setDisabledCheckBox] = useState(false);

  useEffect(() => {
    const fetchUserTickets = async () => {
      setIsLoading(true);
      try {
        const promises = [];
        for (let round = 0; round <= roundIdValue; round++) {
          promises.push(
            readContract({
              address: configs.superlottoContractAddress,
              abi: configs.superlottoContractABI,
              functionName: 'getUserTickets',
              args: [round, userAddress],
            })
          );
        }

        const results = await Promise.all(promises);

        const allTickets = results.map((data) => {
          if (data !== null) {
            return data.map((item) => parseFloat(item.toString()));
          }
          return [];
        });

        setAllTicketsValue(allTickets.flat());
        setPreviousTickets(allTickets.flat());
      } catch (error) {
        console.error('Error fetching user tickets:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isConnected) {
      fetchUserTickets();
    }
  }, [isConnected, roundIdValue, userAddress]);

  useEffect(() => {
    const fetchTicketNumbers = async () => {
      setIsLoading(true);
      const tickets = [];
      for (let i = 0; i < allTicketsValue.length; i++) {
        try {
          const ticketNumber = allTicketsValue[i];
          const data = await readContract({
            address: configs.superlottoContractAddress,
            abi: configs.superlottoContractABI,
            functionName: 'getTicketNumbers',
            args: [ticketNumber],
          });

          if (data !== null) {
            if (Array.isArray(data)) {
              tickets.push({ number: ticketNumber, value: data.toString() });
            } else {
              const numbers = data.toString().split(',');
              tickets.push({ number: ticketNumber, value: numbers });
            }
          }
        } catch (error) {
          console.error('Error fetching ticket numbers:', error);
        }
      }
      setTicketNumbers(tickets);
      setIsLoading(false);
    };

    if (allTicketsValue.length > 0 && isConnected) {
      fetchTicketNumbers();
    }
  }, [allTicketsValue, isConnected, roundIdValue]);

  const allTicket = previousTickets.length;

  const removeTicket = (ticketIndex) => {
    const updatedTicketNumbers = [...ticketNumbers];
    updatedTicketNumbers.splice(ticketIndex, 1); // Remove the ticket at the specified index
    setTicketNumbers(updatedTicketNumbers);
  };


  const handleCheckboxChange = (event, ticketNumber) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedTickets((prevSelectedTickets) => [...prevSelectedTickets, ticketNumber]);
      if (selectedTickets.length >= 20) {
        {
          // Display warning message when exceeding the limit
          message.warning('You can select a maximum of 20 tickets.');
          setDisabledCheckBox(true)
        }
      }
    } else {
      setSelectedTickets((prevSelectedTickets) =>
        prevSelectedTickets.filter((ticket) => ticket !== ticketNumber)
      );
      if (selectedTickets.length >= 20) {
        {
          // Display warning message when exceeding the limit
          message.warning('You can select a maximum of 20 tickets.');
          setDisabledCheckBox(true)
        }
      }
    }

  };

  useEffect(() => {
    const handleTicketSelection = (event) => {
      if (event && event.target) {
        const { checked, value } = event.target;
        if (checked && selectedTicketCount < 20) {
          setSelectedTickets((prevSelectedTickets) => [...prevSelectedTickets, value]);
          setSelectedTicketCount((prevSelectedTicketCount) => prevSelectedTicketCount + 1);
        } else if (!checked) {
          setSelectedTickets((prevSelectedTickets) =>
            prevSelectedTickets.filter((ticket) => ticket !== value)
          );
          setSelectedTicketCount((prevSelectedTicketCount) => prevSelectedTicketCount - 1);
        } else {
          // Display warning message when exceeding the limit
          message.warning('You can select a maximum of 20 tickets.');
        }
      }
    };
    handleTicketSelection()
  }, [selectedTickets.length])



  // console.log('selectedTickets:', selectedTickets);

  const { config: approveTokenWriteConfigs } = usePrepareContractWrite({
    address: buyingTokenAddress,
    abi: buyingTokenConfigs.superlottoBuyingTokenContractABI,
    functionName: 'approve',
    args: [
      configs.superlottoContractAddress,
      ticketPrizeValue,
    ]
  });

  const { writeAsync: approveTokenSync, error: tokenApprovalErrors } = useContractWrite(approveTokenWriteConfigs);

  const { data: buyingToken } = useContractRead({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'buyingToken'
  });

  useEffect(() => {
    if (buyingToken) {
      setBuyingTokenAddress(buyingToken.toString());
    }
  }, [buyingToken]);

  const approveContract = async () => {
    try {
      setIsApproveLoading(true);
      const txReceipt = approveTokenSync && await approveTokenSync();
      const result = txReceipt && await txReceipt.wait();
      // message.success("Token has been approved");
      if (result) {
        notification['success']({
          message: 'Success',
          description: 'Approval Success!',
        });
        setIsTokenApproved(true);
        setIsApproveLoading(false);
      }
      // console.log('result', result);
    } catch (error) {
      message.error(error)
      message.error("Ticket approval has failed!", error);
      setIsApproveLoading(false);
      setIsTokenApproved(false);
    } finally {
      setIsApproveLoading(false);
    }
  };

  const buyTicket = async () => {
    try {
      setIsApproveLoading(true);
      let ticketBuyReceipt, ticketBuyResult;
      ticketBuyReceipt = buyTicketProcessing && await buyTicketProcessing();
      ticketBuyResult = await ticketBuyReceipt?.wait();
      if (ticketBuyResult && !buyTicketErrors) {
        // message.success("Buy tickets successfully!");
        notification['success']({

          message: 'Success',
          description: 'Buy Tickets Successfully!',
        });
      }
      props.onBuyTicketsSuccess("Buy");
      setIsApproveLoading(false);
    } catch (error) {
      message.error("Buy ticket has failed!", error);
      message.error(error)
      setIsTokenApproved(false);
    } finally {
      setIsApproveLoading(false);
    }
  };

  const { writeAsync: buyTicketProcessing, error: buyTicketErrors } = useContractWrite({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'buyTicketsWithPreviousNumbers',
    args: [selectedTickets],
  });

  useEffect(() => {
    const ticketApproveDisable = () => {
      if (selectedTickets.length === 0) {
        setDisabledButton(true);
      }
      else {
        setDisabledButton(false);
      }
    }
    const ticketCost = () => {
      const price = parseFloat(ticketPrize) * parseFloat(selectedTickets.length);
      if (!isNaN(price)) {
        setTicketPrice(price);
      } else {
        setTicketPrice(0);
      }
    };

    ticketCost(); // Initial calculation when component mounts
    ticketApproveDisable();
    return () => {
      ticketCost();
    };
  }, [selectedTickets.length, ticketPrize]);



  return (
    <>
      <div className="claim-card-content mt-4">
        <div className="your-tickets-headline">Your Previous Tickets</div>
        <div className="your-tickets-headline2">
          Tickets<span className='allTicket-align' style={{ padding: '4px', background: '#334467' }}>{selectedTickets.length}</span>
        </div>
      </div>
      <div className="bottom-line mt-3 mb-3" />
      <div className='mt-4 claim-card-headline hide-on-mobile'>
        {previousTickets.length === 0 && !isLoading ? (
          <div className='list-card-ticket'>
            <p className='align-your-tickets'>You have not bought any tickets yet.</p>
          </div>
        ) : isLoading ? (
          <Spin className='mt-5 mb-5' />
        ) : (
          <div className='scrollable-list' style={{ height: '280px', overflowY: 'scroll' }}>
            {previousTickets.map((ticketNumber, index) => (
              <div key={index} className='ticket-list-background-prev mx-auto' style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <Row className='winning-draw-numbers'>
                  <Row className='top-align-prev'>
                    {/* <Col > */}
                    <div className="ticket-checkbox">
                      <Checkbox
                        className="ticket-checkbox"
                        checked={selectedTickets.includes(ticketNumber)}
                        onChange={(event) => handleCheckboxChange(event, ticketNumber)}
                        disabled={selectedTickets.length >= 20 && !selectedTickets.includes(ticketNumber)}
                      >
                        Select
                      </Checkbox>
                    </div>

                    {/* </Col> */}
                    {/* <Col > */}
                    <div className='ticket-close' onClick={() => removeTicket(index)}><CloseOutlined />
                    </div>

                    {/* </Col> */}
                  </Row>
                  <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                    <div className='draw-color-prev'>Tickets {index + 1}</div>
                  </Col>
                  <div className='separate-botom-line mt-2' />
                  <Col className='mt-2' xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                    {Array.isArray(ticketNumbers[index]?.value) ? (
                      ticketNumbers[index]?.value.map((number, subIndex) => {
                        const ticketNumberValue = typeof number === 'object' ? number._hex : number;
                        return (
                          <Avatar
                            key={subIndex}
                            style={{
                              background: '#10183C',
                              color: '#ffffff',
                              fontWeight: '500',
                              margin: '2px',
                            }}
                            className='m-1'
                          >
                            {ticketNumberValue.toString()}
                          </Avatar>
                        );
                      })
                    ) : (
                      ticketNumbers[index]?.value
                        .split(',')
                        .map((number, subIndex) => (
                          <Avatar
                            key={subIndex}
                            style={{
                              color: '#ffffff',
                              fontWeight: '500',
                              margin: '2px',
                              background: '#10183C',
                            }}
                            className='m-1'
                          >
                            {number.trim()}
                          </Avatar>
                        ))
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </div>

        )}
      </div>
      <div className='mt-4 claim-card-headline hide-on-pc'>
        {previousTickets.length === 0 && !isLoading ? (
          <div className='list-card-ticket'>
            <p className='align-your-tickets'>You have not bought any tickets yet.</p>
          </div>
        ) : isLoading ? (
          <Spin className='mt-5 mb-5' />
        ) : (
          <div className='scrollable-list list-card-ticket' style={{ height: '200px', overflowY: 'scroll' }}>
            {previousTickets.map((ticketNumber, index) => (
              <div key={index} className='ticket-list-background ticket-alignment-mobile mx-auto' style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <Row className='winning-draw-numbers-playnow'>
                  <Row className='top-align-prev'>
                    {/* <Col > */}
                    <div className="ticket-checkbox">
                      <Checkbox
                        checked={selectedTickets.includes(ticketNumber)}
                        onChange={(event) => handleCheckboxChange(event, ticketNumber)}
                        disabled={selectedTickets.length >= 20 && !selectedTickets.includes(ticketNumber)}
                      >
                        Select
                      </Checkbox>
                    </div>

                    {/* </Col> */}
                    {/* <Col > */}
                    <div className='ticket-close' onClick={() => removeTicket(index)}><CloseOutlined />
                    </div>

                    {/* </Col> */}
                  </Row>
                  <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                    <div className='draw-color-prev'>Tickets {index + 1}</div>
                  </Col>
                  <div className='separate-botom-line' />
                  <Col className='mt-1' xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                    {Array.isArray(ticketNumbers[index]?.value) ? (
                      ticketNumbers[index]?.value.map((number, subIndex) => {
                        const ticketNumberValue = typeof number === 'object' ? number._hex : number;
                        return (
                          <Avatar
                            key={subIndex}
                            size={25}
                            style={{
                              color: '#ffffff',
                              fontWeight: '100',
                              fontSize: '12px',
                              margin: '2px',
                              background: '#10183C',
                            }}
                            className='m-1'
                          >
                            {ticketNumberValue.toString()}
                          </Avatar>
                        );
                      })
                    ) : (
                      ticketNumbers[index]?.value
                        .split(',')
                        .map((number, subIndex) => (
                          <Avatar
                            key={subIndex}
                            size={25}
                            style={{
                              color: '#ffffff',
                              fontWeight: '100',
                              fontSize: '12px',
                              margin: '2px',
                              background: '#10183C',
                            }}
                            className='m-1'
                          >
                            {number.trim()}
                          </Avatar>
                        ))
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </div>

        )}
      </div>
      <div className='mt-2 button-align-center'>
        {/* {selectedTicketCount >= 20 && (
          <div className='ticket-warning'>You can only select a maximum of 20 tickets.</div>
        )} */}
        {!isTokenApproved ? (
          <Button
            className='m-2'
            type='primary'
            onClick={approveContract}
            loading={isApproveLoading}
            disabled={disabledButton}
          >
            Approve
          </Button>
        ) : (
          <Button
            className='m-2'
            type='primary'
            onClick={buyTicket}
            loading={isApproveLoading}
          >
            Buy Tickets
          </Button>
        )}
      </div>
    </>
  );
};

export default PreviousNumbers;