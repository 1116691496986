import React, { useState, useEffect } from 'react';
import { Avatar, Card } from 'antd';
import { Row } from 'reactstrap';
import { useContractRead } from 'wagmi';
import { configs } from '../../../Blockchain/web3.config';
import axios from 'axios';
import './LastWinningNumbers.css';
import { ethers } from 'ethers';
import WinnerResultsTable from './WinnerResultsTable';

function LastWinningNumbers(props) {
    const [winningNumbers, setWinningNumbers] = useState([]);

    const currentRoundId = props.roundIdNo;
    const lastRoundId = currentRoundId - 1;
    const winnerDetails = props.winnerDetails
    const lastDrawWinners = props.lastDrawWinners


    const { data: winningNo } = useContractRead({
        address: configs.superlottoContractAddress,
        abi: configs.superlottoContractABI,
        functionName: 'getDrawNumbers',
        args: [lastRoundId],
    });

    useEffect(() => {
        if (winningNo) {
            setWinningNumbers(winningNo.toString().split(',')); // Convert to an array
        }
    }, [winningNo]);



    return (
        <div>
            <Card className='fathom-finance-card winning-numbers-card mt-4 mb-2 text-center'>
                <div className='winning-numbers-headline mb-2'>
                    <div className='winning-numbers-head'>Last Winning Numbers - Draw #{lastRoundId}</div>
                </div>
                <div className='hide-on-mobile mt-4 mb-2'>
                    {lastRoundId >= 1 ? (
                        <Row className='winning-numbers-set'>
                            {winningNumbers.map((number, index) => (
                                <div
                                    key={index}
                                    className='avatar-container'
                                    style={{
                                        backgroundImage:
                                            "url(http://www.clker.com/cliparts/6/3/a/f/1206567161447655619SABROG_Glass_Blue_Ball.svg.med.png)",
                                        backgroundSize: 'cover',
                                        color: '#ffffff',
                                        fontWeight: '500',
                                        zIndex: '100',
                                    }}
                                >
                                    <Avatar
                                        size={54}
                                        src='http://www.clker.com/cliparts/6/3/a/f/1206567161447655619SABROG_Glass_Blue_Ball.svg.med.png'
                                        style={{ visibility: 'hidden' }}
                                    />
                                    <div className='number'>{number}</div>
                                </div>
                            ))}
                        </Row>
                    ) : (
                        <p className='winning-numbers-set'>No winning numbers.</p>
                    )}
                </div>
                <div className='hide-on-pc mt-3'>
                    {lastRoundId >= 1 ? (
                        <Row className='winning-numbers-set'>
                            {winningNumbers.map((number, index) => (
                                <div
                                    key={index}
                                    className='avatar-container'
                                    style={{
                                        backgroundImage:
                                            "url(http://www.clker.com/cliparts/6/3/a/f/1206567161447655619SABROG_Glass_Blue_Ball.svg.med.png)",
                                        backgroundSize: 'cover',
                                        color: '#ffffff',
                                        fontWeight: '500',
                                        zIndex: '100',
                                    }}
                                >
                                    <Avatar
                                        size={20}
                                        src='http://www.clker.com/cliparts/6/3/a/f/1206567161447655619SABROG_Glass_Blue_Ball.svg.med.png'
                                        style={{ visibility: 'hidden' }}
                                    />
                                    <div className='number'>{number}</div>
                                </div>
                            ))}
                        </Row>
                    ) : (
                        <p className='winning-numbers-set'>No winning numbers.</p>
                    )}
                </div>
                <div className='winning-numbers-headline hide-on-mobile mb-2 mt-5'>
                    <div className='winning-numbers-head'>Last Draw Winners {lastDrawWinners}</div>
                </div>
                <div className='winning-numbers-headline hide-on-pc mb-2 mt-4'>
                    <div className='winning-numbers-head'>Last Draw Winners {lastDrawWinners}</div>
                </div>

                <div className='mt-4'>
                    {lastDrawWinners >= 1 ?
                        (
                            <div>
                                <div className='claim-card-content-last-winning'>
                                    <span className='wn-sub-text'>Winners</span>
                                    <span className='wn-sub-text2'>Amount</span>
                                </div>
                                <Row>
                                    <div className='winner-result-table-alignment mx-auto'>
                                        <WinnerResultsTable winnerDetails={winnerDetails} />
                                    </div>
                                </Row>
                            </div>
                        )
                        : (
                            <p className='winning-numbers-set'>No winners.</p>
                        )}
                </div>
            </Card>
        </div>
    );
}

export default LastWinningNumbers;
