import React from 'react'
import ResultsTable from './ResultsTable'
import { Card } from 'antd'
import './ResultsComponents.css'

function ResultTableCard(props) {
    // console.log('pool2', props.jackPotValue);
    return (
        <div className=''>
            {/* <Card className='results-table-card mt-4 mb-2'> */}
            <Card className='table-align-results-rule fathom-finance-card results-table-card mt-4 mb-2 text-center ' >
                <div className='claim-card-headline bottom-line-align'>Prize Pool Allocations - Each category is split among all winners</div>
                <div className="bottom-line mt-2 mb-3" />
                <ResultsTable jackpotValue={props.jackPotValue} />
            </Card>
        </div>
    )
}

export default ResultTableCard