import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NavBar.css';
import Logo from '../../images/Fathom-logo.svg';
import LogoMobile from '../../images/fflogo.png';
import { Button, Spin, Avatar, Dropdown, Menu, Space } from 'antd';
import { Menu as MenuIcon } from 'react-feather';
import MobileMenu from '../MobileMenu/MobileMenu';
import { useDispatch, useSelector } from 'react-redux';
import { open } from '../../Redux/SideMenu';
import { getUserProfileData, logout } from '../../Redux/AuthSlice';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { fetchClientIP } from '../../Redux/AuthSlice';
import WalletConnect from '../WalletConnect/WalletConnect';
import { Col, Row } from 'reactstrap';

function NavBar() {
  // auth related variables
  const { token, loading, error, userProfile } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleShowMobileMenu = () => {
    dispatch(open());
  };

  // fetch client ip
  useEffect(() => {
    dispatch(fetchClientIP());
  }, []);

  // if there is a token fetch user profile details
  useEffect(() => {
    if (token) {
      setIsUserLoggedIn(true);
      dispatch(getUserProfileData(token));
    } else {
      setIsUserLoggedIn(false);
    }
  }, [token]);

  // if error occur when fetch user profile data, logout user
  useEffect(() => {
    if (error) {
      dispatch(logout());
    }
  }, [error]);

  // to navigate to user profile
  const naviateToAccount = () => {
    navigate('/profile');
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <a onClick={naviateToAccount}>Profile</a>,
          icon: (
            <Avatar
              size="small"
              style={{ marginLeft: '-5px', backgroundColor: '#30317C' }}
              icon={
                <div style={{ marginTop: '-3px' }}>
                  <UserOutlined />
                </div>
              }
            />
          ),
        },
        {
          key: '2',
          label: <a onClick={handleLogout}>Logout</a>,
          icon: (
            <Avatar
              size="small"
              style={{ marginLeft: '-5px', backgroundColor: '#fa541c' }}
              icon={
                <div style={{ marginTop: '-3px' }}>
                  <LogoutOutlined />
                </div>
              }
            />
          ),
        },
      ]}
    />
  );

  const toolsMenu = (
    <Menu
      items={[
        {
          key: '1',
          label: <a target="_blank" rel="noopener noreferrer" href="https://migrator.rugfreecoins.com">Migrator</a>,
        },
        {
          key: '2',
          label: <a target="_blank" rel="noopener noreferrer" href="https://airdrop.rugfreecoins.com">Airdrops</a>,
        },
      ]}
    />
  );

  const airdropMenu = (
    <Menu
      items={[
        {
          key: '1',
          // label: <Link to="/airdrop-list"><a>Airdrop List</a></Link>,
        },
        {
          key: '2',
          // label: <Link to="/create-airdrop"><a>Create Airdrop</a></Link>,
        },
      ]}
    />
  );

  return (
    <nav className="fixed-top bg-blur-nav pt-3 pb-3">
      <div className="col-lg-9 mx-auto d-flex justify-content-between">
        {/* site logo */}
        <div className="navbar-brand hide-on-mobile d-flex mt-1">
          <div style={{ marginTop: '-3px' }}>
            <Link to="/">
              <img src={Logo} alt="main_logo" style={{ width: '175px', height: '44px' }} />
            </Link>
          </div>
          {/* <div>
            <Link to="/">
              <h4 className="fw-bold logo-text hide-on-mobile">Fathom</h4>
            </Link>
          </div> */}
        </div>
        <div className="navbar-brand hide-on-pc d-flex mt-1">
          <div style={{ marginLeft: '10px', marginTop: '-6px' }}>
            <Link to="/">
              <img src={Logo} alt="main_logo" style={{ width: '135px', height: '44px' }} />
            </Link>
          </div>
          {/* <div>
            <Link to="/">
              <h4 className="fw-bold logo-text hide-on-mobile">Fathom</h4>
            </Link>
          </div> */}
        </div>

        {/* header menu links to show on large devices in center -> will hide on small devices */}
        {/* <div className="d-flex hide-on-mobile center centered-navbar">
          <ul className="list-inline">
            <li className="list-inline-item">
              <Link to="/" className="navbarlink">
                Fathom Lottery
              </Link>
            </li>
          </ul>
        </div> */}

        {/* button & hamburger menu icon to show on large devices in right side */}
        <div className='mt-1'>
          <Row>
            <Col className='wallet-connect-align hide-on-pc'>
              <WalletConnect />
            </Col>
            <Col className='hide-on-mobile wallet-connect-align2'>
              <WalletConnect />
            </Col>
            <Col className='mt-1'>
              {/* <MenuIcon className="hide-on-pc" onClick={handleShowMobileMenu} /> */}
              {/* Mobile side menu will show up when hamburger menu icon clicked */}
              {/* <MobileMenu /> */}
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="navbar-bottom-line" /> */}
    </nav>
  );
}

export default NavBar;
