import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpened: false
}

export const mobileMenuSlice = createSlice({
  name: "mobile-menu",
  initialState,
  reducers: {
    open: (state) => {
      state.isOpened = true;
    },
    close: (state) => {
        state.isOpened = false;
    }, 
  }
});

// Action creators are generated for each case reducer function
export const { open, close } = mobileMenuSlice.actions;

export default mobileMenuSlice.reducer;
