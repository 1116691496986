import { Layout } from 'antd';
import React, { useState } from 'react';
import NavBar from './Components/NavBar/NavBar';
import Pages from './Pages/Pages';
import FooterContent from './Components/FooterContent/Footer';
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import BackgroundVideo from './Components/BackgroundVideo/BackgroundVideo';

function LayoutView() {

    const { Content, Footer } = Layout;
    const [hideNavBar, setHideNavBar] = useState(false)

    // AOS init
    useEffect(() => {
        AOS.init({
            once: true,
            useClassNames: true,
            duration: 700
        });
    }, [])

    return (
        <Layout className="layout">
            {
                !hideNavBar &&
                <>
                    <div style={{ zIndex: '999' }} className='col-lg-9 mx-auto'>
                        <NavBar />
                    </div>
                </>

            }
            {/* <Layout> */}
            <Content style={{ minHeight: '100vh' }} className="site-layout-background">
                <BackgroundVideo />
                <div className='content col-lg-9 hide-on-mobile mx-auto'>
                    <Pages />
                </div>
                <div className='content container hide-on-pc'>
                    <Pages />
                </div>
            </Content>

            <Footer className="text-center footer">
                {/* <FooterContent /> */}
                <span className="text-muted">
                    COPYRIGHT © {new Date().getFullYear()} collaborated by <br />
                    <span style={{ color: '#00b0f2' }}><a href='https://www.fathom.finance/' className='text-color-style' target={'_blank'}> Fathom Finance</a></span> and developed by
                    <span style={{ color: '#00b0f2' }}><a href='https://perfectustec.com/' className='text-color-style' target={'_blank'}> perfectus</a></span>. <br />
                    All rights Reserved
                    <br />
                </span>
            </Footer>
            {/* </Layout> */}
        </Layout>
    )
}

export default LayoutView