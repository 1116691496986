import React, { useState, useEffect } from 'react';
import { Avatar, Card, Spin } from 'antd';
import { useContractRead } from 'wagmi';
import { configs } from '../../../Blockchain/web3.config';
import { readContract } from '@wagmi/core';
import imageUrl from '../../../images/ticket-bg.png'
import imageUrl2 from '../../../images/ticket-bg.svg'

import './WinnersTickets.css';
import { Col, Row } from 'reactstrap';

function WinnerDraw(props) {
    const [winningNumbers, setWinningNumbers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchResults = async () => {
            setIsLoading(true);
            const results = [];
            const lastRoundId = props.roundIdNo - 1;

            for (let currentRoundId = lastRoundId; currentRoundId >= 1; currentRoundId--) {
                const data = await readContract({
                    address: configs.superlottoContractAddress,
                    abi: configs.superlottoContractABI,
                    functionName: 'getDrawNumbers',
                    args: [currentRoundId],
                });

                if (data) {
                    results.push(data.toString().split(','));
                }
            }

            setWinningNumbers(results);
            setIsLoading(false);
        };

        fetchResults();
    }, [props.roundIdNo]);

    const isMobileView = window.innerWidth <= 768;

    return (
        <>
            <Card className='fathom-finance-card your-ticket-card mt-4 mb-2 text-center'>
                <div className='text-center'>
                    <h5 className='claim-card-headline'>Winning Numbers of Recent Draws</h5>
                </div>
                <div className='bottom-line mt-3 mb-3' />
                <div className='mt-4 claim-card-headline'>
                    {isLoading ? (
                        <Spin className='mt-5 mb-5' />
                    ) : winningNumbers.length === 0 ? (
                        <div className='list-card-ticket'>
                            <p className='align-your-tickets'>There are no recent draws yet.</p>
                        </div>
                    ) : (
                        <div
                            className='scrollable-list'
                            style={{ height: !isMobileView ? '280px' : '180px', overflowY: 'auto', paddingRight: '12px' }}>
                            <div className='list-card-ticket hide-on-mobile'>
                                {winningNumbers.map((numbers, roundIndex) => (
                                    <div className='ticket-list-background mx-auto' style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} key={`round-${roundIndex}`}>
                                        <Row className='winning-draw-numbers'>
                                            <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                                <div className='mt-4 draw-color'>Draw #{props.roundIdNo - roundIndex - 1}</div>
                                            </Col>
                                            <div className='separate-botom-line mt-3' />
                                            <Col className='mt-2' xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                                {numbers.map((number, index) => (
                                                    <Avatar
                                                        key={index}
                                                        style={{
                                                            background: '#10183C',
                                                            color: '#ffffff',
                                                            fontWeight: '500',
                                                            margin: '2px',
                                                        }}
                                                        className='m-1'
                                                    >
                                                        {number}
                                                    </Avatar>
                                                ))}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                            <div className='list-card-ticket hide-on-pc'>
                                {winningNumbers.map((numbers, roundIndex) => (
                                    <div className='ticket-list-background ticket-alignment-mobile mx-auto' style={{ backgroundImage: `url(${imageUrl2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} key={`round-${roundIndex}`}>
                                        <Row className='winning-draw-numbers'>
                                            <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                                <div className='draw-color'>Draw #{props.roundIdNo - roundIndex - 1}</div>
                                            </Col>
                                            <div className='separate-botom-line mt-1' />
                                            <Col className='mt-1' xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                                {numbers.map((number, index) => (
                                                    <Avatar
                                                        key={index}
                                                        size={25}
                                                        style={{
                                                            color: '#ffffff',
                                                            fontWeight: '500',
                                                            fontSize: '12px',
                                                            margin: '2px',
                                                            background: '#10183C',
                                                        }}
                                                        className='m-1'
                                                    >
                                                        {number}
                                                    </Avatar>
                                                ))}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </>
    );
}

export default WinnerDraw;
