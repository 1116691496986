import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSuccess: false,
};

const BuyTicketSlice = createSlice({
    name: 'buyTicket',
    initialState,
    reducers: {
        setSuccess: (state) => {
            state.isSuccess = true;
        },
        resetState: () => initialState,
    },
});

// // Async action creator
// export const buyTicketsNow = (inputNumberValue) => async (dispatch) => {
//     try {
//         // Your logic for calling the contract write function goes here
//         // For example, await writeFunction(inputNumberValue);
//         dispatch(setSuccess());
//     } catch (error) {
//         // Handle error if needed
//     }
// };

// Selector
export const selectIsSuccess = (state) => state.buyTicket.isSuccess;

// Export reducer and actions
export const { setSuccess, resetState } = BuyTicketSlice.actions;
export default BuyTicketSlice.reducer;
