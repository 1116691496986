import React from 'react'
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import './MobileMenu.css'
import { XCircle, Home } from 'react-feather'
import { useSelector, useDispatch } from "react-redux";
import { close } from '../../Redux/SideMenu';
import { logout } from '../../Redux/AuthSlice';
// import WalletConnect from '../WalletConnect/WalletConnect';

function MobileMenu() {

    const { token } = useSelector((state) => state.auth)
    const { isOpened } = useSelector((state) => state.sideMenu);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(close())
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <Drawer
            placement="left"
            onClose={onClose}
            visible={isOpened}
            className="front-menu hide-on-mobile"
            width="270px"
            closable={false}
        >
            <div className="row justify-content-start pt-4">
                <XCircle className="close-icon mb-3" onClick={onClose} />
                <Link to="/" className="mt-3" onClick={onClose}><span className="text-light mobile-nav-link">Home</span></Link>
            </div>
        </Drawer>
    )
}

export default MobileMenu