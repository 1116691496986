import { configureStore } from "@reduxjs/toolkit";
import sideMenuReducer from "./SideMenu";
import AuthSlice from "./AuthSlice";
import BuyTicketReducer from "./BuyTicketSlice";

export const store = configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
    auth: AuthSlice,
    buyTicket: BuyTicketReducer,
  }
});
