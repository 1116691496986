import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import TokenListPage from './TokensListPage/TokenListPage';
import Admin from './Admin/Admin';
import NotFound from './NotFound'
import { useAccount, useContractRead } from 'wagmi';
import { configs } from '../Blockchain/web3.config'

function Pages() {
  const { address } = useAccount();
  const [adminAddress, setAdminAddress] = useState('');
  const userAddress = address;

  const { data: owner } = useContractRead({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'owner',
  });

  useEffect(() => {
    if (owner) {
      setAdminAddress(owner);
    }
    // console.log('owner', adminAddress);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<TokenListPage />} />
      <Route path="/admin" element={userAddress === adminAddress ? <Admin /> : <NotFound />} />
      <Route path="*" element={<NotFound />} />
      {/* <Route path="/test" element={<Admin />} /> */}
    </Routes>
  );
}

export default Pages;
