import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "../Services/Api";

// initialize userToken from local storage
const token = localStorage.getItem('rfc_token')
    ? localStorage.getItem('rfc_token')
    : null

const initialState = {
    loading: false,
    token,
    error: null,
    userProfile: null,
    success: false,
}

export const loginUser = createAsyncThunk('/auth/login', async (userData) => {
    return api
        .post('/auth/login', userData)
        .then((response) => response.data.payload)
})

export const registerUser = createAsyncThunk('/auth/register', async (userData) => {
    return api  
        .post('/auth/register', userData)
        .then((response) => response.data.payload)
})

export const getUserProfileData = createAsyncThunk('/auth/profile', async (token) => {
    return api
        .get('/auth/profile', {headers: { 'Authorization' : `Bearer ${token}` }})
        .then((response) => response.data.payload)
})

export const fetchClientIP = createAsyncThunk('fetchClientIP', async (token) => {
    return api
        .get('https://api.ipify.org', {params: { format: "json" }})
        .then((response) => response.data)
})

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => {
            state.error = null
            state.success = false
        },
        logout: (state) => {
            localStorage.removeItem('rfc_token')
            state.token = null
            state.userProfile = null
            state.error = null
            state.success = null
        }
    },
    extraReducers: (builder) => {
        // login 
        builder.addCase(loginUser.pending, (state) => {
            state.loading = true
            state.success = false
            state.error = null
            state.token = null
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false
            state.error = null
            state.token = action.payload.token
            localStorage.setItem('rfc_token', action.payload.token)
            state.success = true
        })
        builder.addCase(loginUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
            state.success = false
            state.token = null
        })

        // register
        builder.addCase(registerUser.pending, (state) => {
            state.loading = true
            state.success = false
            state.error = null
            state.token = null
        })
        builder.addCase(registerUser.fulfilled, (state, action) => {
            state.loading = false
            state.error = null
            state.token = action.payload.token
            localStorage.setItem('rfc_token', action.payload.token)
            state.success = true
        })
        builder.addCase(registerUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
            state.success = false
            state.token = null
        })

        // profile data
        builder.addCase(getUserProfileData.pending, (state) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(getUserProfileData.fulfilled, (state, action) => {
            state.loading = false
            state.error = null
            state.userProfile = action.payload.user
        })
        builder.addCase(getUserProfileData.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        // client IP
        builder.addCase(fetchClientIP.fulfilled, (state, action) => {
            localStorage.setItem('rfc_client_ip', action.payload.ip)
        })
    }
})

export const { reset, logout } = authSlice.actions

export default authSlice.reducer