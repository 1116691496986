import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { Button, Card, Modal, Spin } from 'antd';
import { configs } from '../../../Blockchain/web3.config';
import { useContractRead } from 'wagmi';
import { utils } from 'ethers';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import './JackpotCountdowncard.css';
import Countdown from './Countdown/CountdownComponent';
import PlayNow from '../PlayNow/PlayNow';
// import { readContract } from '@wagmi/core';

function JackpotCountdownCard(props) {
    const buyingTokenAddress = props.buyingTokenAddress;
    const roundIdValue = props.roundIdNo;
    // console.log('roundIdValue00', roundIdValue);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [playNowLoading, setPlayNowLoading] = useState(true);
    const [ticketPrizeValue, setTicketPrizeValue] = useState();

    const { data: ticketPrize } = useContractRead({
        address: configs.superlottoContractAddress,
        abi: configs.superlottoContractABI,
        functionName: 'ticketPrize',
    });

    // useEffect(() => {
    //     console.log('BuyingTokenAddress 2', buyingTokenAddress);
    // }, [])

    useEffect(() => {
        if (ticketPrize) {
            setTicketPrizeValue(utils.formatUnits(ticketPrize.toString()));
            // console.log('ticketPrize', ticketPrize);
        }
        // console.log('ticketPrizeValue', ticketPrizeValue);
    }, [ticketPrize, ticketPrizeValue]);

    const formattedTicketValue = ticketPrizeValue !== '' ? parseFloat(ticketPrizeValue).toFixed(0) : '';

    const showModal = () => {
        setIsModalOpen(true);
    };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(true);
    // };
    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Card className='fathom-finance-card jackpot-countdown-card mt-4 mb-2 text-center' >
                <Row className='mt-3 mb-2'>
                    <div className='jackpot-countdown-headline'>Time left to join draw #{roundIdValue}</div>
                </Row>
                <Row>
                    <div className='winning-numbers-set mb-1'>
                        <Countdown drawFinished={props.drawFinished} />
                    </div>
                </Row>
                {/* {!buyingTokenAddress && isConnected ? <Spin className='mt-4 mb-1' /> : */}
                <Row className='mt-2 mb-2'>
                    <div className='button-align-center mt-1 mb-1'>
                        <>
                            <ConnectButton.Custom>
                                {({
                                    account,
                                    chain,
                                    openAccountModal,
                                    openChainModal,
                                    openConnectModal,
                                    authenticationStatus,
                                    mounted,
                                }) => {
                                    const ready = mounted && authenticationStatus !== 'loading';
                                    const connected =
                                        ready &&
                                        account &&
                                        chain &&
                                        (!authenticationStatus || authenticationStatus === 'authenticated');

                                    return (
                                        <div
                                            {...(!ready && {
                                                'aria-hidden': true,
                                                style: {
                                                    opacity: 0,
                                                    pointerEvents: 'none',
                                                    userSelect: 'none',
                                                },
                                            })}
                                            className='mx-3'
                                        >
                                            {(() => {
                                                if (!connected) {
                                                    return (
                                                        <>
                                                            <Button onClick={openConnectModal} type='primary' style={{
                                                                height: '40px', minWidth: '170px',
                                                            }}>
                                                                Connect Wallet
                                                            </Button>
                                                        </>
                                                    );
                                                }

                                                if (chain.unsupported) {
                                                    return (
                                                        <Button
                                                            onClick={openChainModal}
                                                            type='primary'
                                                            style={{
                                                                //     display: 'flex',
                                                                //     alignItems: 'center',
                                                                //     background: '#4648d8',
                                                                //     borderRadius: '8px',
                                                                //     fontSize: '16px',
                                                                //     fontWeight: '700',
                                                                minWidth: '170px',
                                                                height: '40px',
                                                            }}
                                                        >
                                                            Wrong network
                                                        </Button>
                                                    );
                                                }

                                                return (
                                                    <div style={{ display: 'flex', gap: 12 }}>
                                                        <div className='d-flex header-wallet-button-align mx-1' onClick={showModal}>
                                                            <Button type='primary' loading={!buyingTokenAddress} style={{ minWidth: '170px', height: '40px' }}>
                                                                Play Now!
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    );
                                }}
                            </ConnectButton.Custom>
                        </>
                    </div>
                    <div>
                        <p className='mt-1 ticket-price-botomline'>Ticket Price: {formattedTicketValue} USDT</p>
                    </div>
                </Row>
                {/* } */}
            </Card>
            <Modal className='text-center' open={isModalOpen} onCancel={handleClose} maskClosable={false} footer={false}>
                <PlayNow buyingTokenAddress={buyingTokenAddress} roundIdValue={roundIdValue} ticketPrize={ticketPrizeValue} onBuyTicketsSuccess={props.onBuyTicketsSuccess} />
            </Modal>
        </>
    );
}

export default JackpotCountdownCard;
