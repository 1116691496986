import { Input, Form, Button, message, Modal, notification } from "antd";
import React, { useState, useEffect } from "react";
import { useContractWrite, useContractRead, usePrepareContractWrite, useAccount, useSigner } from "wagmi";
import { configs, buyingTokenConfigs, commonConfig } from '../../../Blockchain/web3.config';
import { readContract } from '@wagmi/core';
import { utils, ethers } from 'ethers';
import EditNumbers from "./EditNumbers";
import { Col, Row } from 'reactstrap';
import './PlayNow.css';
import PreviousNumbers from "./PreviousNumbers";

function PlayNow(props) {
    const ticketPrize = props.ticketPrize;
    const roundIdValue = props.roundIdValue;
    const buyingTokenAddress = props.buyingTokenAddress;
    const { address } = useAccount();
    const userAddress = address;
    const { data: signer } = useSigner();

    const [isApproveLoading, setIsApproveLoading] = useState(false);
    const [inputNumberValue, setInputNumberValue] = useState('');
    const [ticketPrice, setTicketPrice] = useState(0);
    const [ticketCostValue, setTicketCostValue] = useState(0);
    const [isTokenApproved, setIsTokenApproved] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [allowance, setAllowance] = useState(0);

    async function approveTokens(buyingTokenAddress, ticketCostValue, configs) {
        try {
            const chainId = process.env.REACT_APP_CHAIN_ID;
            const provider = new ethers.providers.JsonRpcProvider(
                process.env.REACT_APP_PUBLIC_BSC_RPC_PROVIDER
            );
            const erc20ContractInstance = new ethers.Contract(
                buyingTokenAddress,
                buyingTokenConfigs.superlottoBuyingTokenContractABI,
                provider
            );
            const erc20ContractInstanceWithSigner = erc20ContractInstance.connect(signer);
            const spender = configs.superlottoContractAddress;

            const approveTokenReceipt = await erc20ContractInstanceWithSigner.approve(
                spender,
                ticketCostValue
            );
            const result = approveTokenReceipt;
            return result;
        } catch (error) {
            let errorMessage =
                'Something went wrong while trying to approve the token. Please try again';
            if (error && error.message) {
                errorMessage = error.message;
            }
            if (error && error.reason && error.reason !== '') {
                errorMessage = error.reason;
            }
            throw errorMessage;
        }
    }

    async function buyingTickets(configs, inputNumberValue) {
        try {
            const chainId = process.env.REACT_APP_CHAIN_ID;
            const provider = new ethers.providers.JsonRpcProvider(
                process.env.REACT_APP_PUBLIC_BSC_RPC_PROVIDER
            );

            const erc20ContractInstance = new ethers.Contract(
                configs.superlottoContractAddress,
                configs.superlottoContractABI,
                provider
            );

            const erc20ContractInstanceWithSigner = erc20ContractInstance.connect(signer);

            const buyTokenReceipt = await erc20ContractInstanceWithSigner.buyTicket(
                inputNumberValue
            );
            const result = buyTokenReceipt;
            return result;
        } catch (error) {
            let errorMessage =
                'Something went wrong while trying to buy the ticket. Please try again';
            if (error && error.message) {
                errorMessage = error.message;
            }
            if (error && error.reason && error.reason !== '') {
                errorMessage = error.reason;
            }
            throw errorMessage;
        }
    }

    const ticketCost = () => {
        const price = parseFloat(ticketPrize) * parseFloat(inputNumberValue);
        if (!isNaN(price)) {
            setTicketPrice(price);
        } else {
            setTicketPrice(0);
        }
    };

    useEffect(() => {
        handleAllowance();
    }, []);

    const handleAllowance = async () => {
        const handleAllowanceValue = await readContract({
            address: buyingTokenAddress,
            abi: commonConfig.commonERC20ContractABI,
            functionName: 'allowance',
            args: [
                userAddress,
                configs.superlottoContractAddress,
            ]
        });
        setAllowance(utils.formatEther(handleAllowanceValue));
    };

    useEffect(() => {
        if (allowance >= ticketPrice && ticketPrice > 0) {
            setIsTokenApproved(true);
        }
    }, [ticketPrice]);

    useEffect(() => {
        ticketCost();
        handleAllowance();
    }, [inputNumberValue, ticketPrize]);

    useEffect(() => {
        const ticketCostValue = isNaN(ticketPrice) ? 0 : utils.parseUnits(ticketPrice.toString());
        setTicketCostValue(ticketCostValue);
    }, [ticketPrice]);

    const handleChange = (e) => {
        const value = e.target.value;
        setIsTokenApproved(false);
        setInputNumberValue(value);
        setIsButtonDisabled(false);
    };

    const handleApproveClick = () => {
        if (inputNumberValue <= 0) {
            message.error("You should add a valid value to the input field.");
        } else {
            approveContract();
        }
    };

    const approveContract = async () => {
        try {
            setIsApproveLoading(true);
            const txReceipt = await approveTokens(buyingTokenAddress, ticketCostValue, configs);
            const result = await txReceipt?.wait();
            if (result) {
                notification['success']({
                    message: 'Success',
                    description: 'Approval Success!',
                });
                setIsTokenApproved(true);
                setIsApproveLoading(false);
            }
        } catch (error) {
            message.error(error);
            setIsApproveLoading(false);
            setIsTokenApproved(false);
        } finally {
            setIsApproveLoading(false);
        }
    };

    const handleBuyTicketClick = () => {
        buyTicket();
    };

    const buyTicket = async () => {
        try {
            setIsApproveLoading(true);
            const ticketBuyReceipt = await buyingTickets(configs, inputNumberValue);
            const ticketBuyResult = await ticketBuyReceipt?.wait();
            if (ticketBuyResult) {
                notification['success']({
                    message: 'Success',
                    description: 'Buy Tickets Successfully!',
                });
            }
            props.onBuyTicketsSuccess("Buy");
            setIsApproveLoading(false);
        } catch (error) {
            message.error(error);
            setIsTokenApproved(false);
        } finally {
            setIsApproveLoading(false);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showModal1 = () => {
        setIsModalOpen1(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setIsModalOpen1(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalOpen1(false);
    };
    return (
        <>
            <div className='play-now pt-2 pb-2'>
                <div className='mt-2'>
                    <div className="buy-now">Buy Your Tickets</div>
                    <div className="bottom-line mt-1 mb-3" />
                    <div>
                        <p className="quantity-text">Quantity</p>
                        <Form>
                            <Form.Item
                                name='inputNumberValue'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your ticket quantity!',
                                    },
                                ]}
                            >
                                <Input
                                    lang='en'
                                    size='large'
                                    type='number'
                                    placeholder='0'
                                    value={inputNumberValue}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='claim-card-content'>
                        <h5 className='sub-text'>Total amount:</h5>
                        <h5 className='sub-text'>{ticketPrice} USDT</h5>
                    </div>
                    <div className='button-align-center hide-on-mobile'>
                        <Col>
                            <Row>
                                {!isTokenApproved ? (
                                    <Button
                                        className='m-2 m-button-size mx-auto'
                                        type='primary'
                                        onClick={handleApproveClick}
                                        loading={isApproveLoading}
                                        disabled={isButtonDisabled}
                                    >
                                        Approve
                                    </Button>
                                ) : (
                                    <Button
                                        className='m-2 m-button-size mx-auto'
                                        type='primary'
                                        onClick={handleBuyTicketClick}
                                        loading={isApproveLoading}
                                        disabled={isButtonDisabled}
                                    >
                                        Buy Tickets
                                    </Button>
                                )}</Row>
                            <Row>
                                <Button className='m-2 m-button-size mx-auto' type='primary' onClick={showModal1}>
                                    Buy Previous Tickets
                                </Button>
                            </Row>
                            <Row>
                                <Button className='m-2 m-button-size mx-auto' type='primary' onClick={showModal}>
                                    Buy Editable Tickets
                                </Button>
                            </Row>
                        </Col>
                    </div>
                    <div className='button-align-center hide-on-pc'>
                        <Col>
                            <Row>
                                {!isTokenApproved ? (
                                    <Button
                                        className='m-2 m-button-size mx-auto'
                                        type='primary'
                                        onClick={handleApproveClick}
                                        loading={isApproveLoading}
                                        disabled={isButtonDisabled}
                                    >
                                        Approve
                                    </Button>
                                ) : (
                                    <Button
                                        className='m-2 m-button-size mx-auto'
                                        type='primary'
                                        onClick={handleBuyTicketClick}
                                        loading={isApproveLoading}
                                    >
                                        Buy Tickets
                                    </Button>
                                )}
                            </Row>
                            <Row>
                                <Button className='m-2 m-button-size mx-auto' type='primary' onClick={showModal1}>
                                    Buy Previous Tickets
                                </Button>
                            </Row>
                            <Row>
                                <Button className='m-2 m-button-size mx-auto' type='primary' onClick={showModal}>
                                    Buy Editable Tickets
                                </Button>
                            </Row>
                        </Col>
                    </div>
                </div>
                <Modal maskClosable={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                    <EditNumbers ticketPrize={ticketPrize} buyingTokenAddress={buyingTokenAddress} onBuyTicketsSuccess={props.onBuyTicketsSuccess} />
                </Modal>
                <Modal maskClosable={false} open={isModalOpen1} onOk={handleOk} onCancel={handleCancel} footer={false}>
                    <PreviousNumbers roundIdValue={roundIdValue} buyingTokenAddress={buyingTokenAddress} ticketPrize={ticketPrize} onBuyTicketsSuccess={props.onBuyTicketsSuccess} />
                </Modal>
            </div>
        </>
    );
}

export default PlayNow;