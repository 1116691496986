import React from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { getEllipsisTxt } from '../../Helpers/Formatters'
import { Button } from 'antd';

function WalletConnect() {
    return (
        <>
            <ConnectButton.Custom>
                {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                            authenticationStatus === 'authenticated');

                    return (
                        <div
                            {...(!ready && {
                                'aria-hidden': true,
                                'style': {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                            })}
                            className='mx-2'
                        >
                            {(() => {
                                if (!connected) {
                                    return (
                                        <>
                                            {/* <Button onClick={openConnectModal} type="primary" size='small' className="d-flex hide-on-pc">Connect Wallet</Button> */}
                                            <Button size='large' onClick={openConnectModal} type="primary" >Connect Wallet</Button>
                                        </>
                                    );
                                }

                                if (chain.unsupported) {
                                    return (
                                        <Button onClick={openChainModal}
                                            type='primary'
                                            size='large'
                                        // className='network-button'
                                        // style={{ display: 'flex', alignItems: 'center', background: '#4648d8', borderRadius: '8px', fontSize: '16px', fontWeight: '700', height: '40px' }}
                                        // type="text"
                                        >
                                            Wrong network
                                        </Button>
                                    );
                                }

                                return (
                                    <div style={{ display: 'flex', gap: 12 }}>
                                        {/* <Button
                                            // className='network-button'
                                            onClick={openChainModal}
                                            type='primary'
                                            // size='large'
                                            style={{ display: 'flex', color: '#ffffff', alignItems: 'center', background: '#4648d8', borderRadius: '8px', fontSize: '16px', fontWeight: '600', height: '40px' }}
                                        // type="text"
                                        > */}
                                        {/* {chain.hasIcon && (
                                                <div
                                                    style={{
                                                        background: chain.iconBackground,
                                                        width: 18,
                                                        height: 18,
                                                        borderRadius: 999,
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {chain.iconUrl && (
                                                        <img
                                                            alt={chain.name ?? 'Chain icon'}
                                                            src={chain.iconUrl}
                                                            style={{ width: 18, height: 18, marginTop: '-12px' }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            <span className='hide-on-mobile' style={{ marginLeft: '4px' }}>
                                                {chain.name}
                                            </span>
                                        </Button> */}

                                        <div className='d-flex header-wallet-button-align mx-1' onClick={openAccountModal}>
                                            {/* <span className='header-wallet-address'> */}
                                            <Button type='primary' style={{ minWidth: '140px' }}>
                                                {getEllipsisTxt(account.displayName, 4)}
                                            </Button>
                                            {/* </span> */}
                                        </div>
                                    </div>
                                );
                            })()}
                        </div>
                    );
                }}
            </ConnectButton.Custom>
        </>
    )
}

export default WalletConnect