import React, { useEffect, useState } from 'react';
import './TokenListPage.css';
import { Col, Row } from 'reactstrap';
import { useContractRead } from 'wagmi';
import { readContract } from '@wagmi/core';
import { configs, commonConfig } from '../../Blockchain/web3.config';
import JackpotValueCard from '../../Components/SupperLottoComponents/JackpotValue/JackpotValueCard';
import JackpotCountdownCard from '../../Components/SupperLottoComponents/JackpotCountdown/JackpotCountdownCard';
import LastWinningNumbers from '../../Components/SupperLottoComponents/LastWinningNumbers/LastWinningNumbers';
import TicketsCard from '../../Components/SupperLottoComponents/ClaimCards/TicketsCard';
import YourTickets from '../../Components/SupperLottoComponents/WinnersTicketsComponents/YourTickets';
import WinnerDraw from '../../Components/SupperLottoComponents/WinnersTicketsComponents/WinnerDraw';
import TicketsDrawPrizes from '../../Components/SupperLottoComponents/WinnersTicketsComponents/TicketsDrawPrizes';
import ResultTableCard from '../../Components/SupperLottoComponents/ResultsComponents/ResultTableCard';
import PlayersInsight from '../../Components/SupperLottoComponents/PlayersInsight/PlayersInsight';
import { utils } from 'ethers';
import HeaderSection from '../../Components/SupperLottoComponents/HeaderSection/HeaderSection';
import axios from 'axios';
import { message } from 'antd';

function TokenListPage() {
  const [jackPotValue1, setJackpotValue1] = useState('');
  const [roundIdValue, setRoundIdValue] = useState();
  const [reloadKey, setReloadKey] = useState(0);
  const [reloadCountdown, setReloadCountdown] = useState(0);
  const [buyingTokenAddress, setBuyingTokenAddress] = useState('');
  const [balanceOfPool, setBalanceOfPool] = useState(0);
  const [winnerDetails, setWinnerDetails] = useState([]);
  const [lastDrawWinners, setLastDrawWinners] = useState(0);

  const { data: buyingToken } = useContractRead({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'buyingToken'
  });

  useEffect(() => {
    if (buyingToken) {
      setBuyingTokenAddress(buyingToken.toString());
    }

  }, []);

  const handleBuyTicketsSuccess = (message) => {
    if (message === 'Buy') {
      setReloadKey((prevKey) => prevKey + 1);
    }
  };

  const handleDrawFinished = (message) => {
    if (message === 'drawFinished') {
      setReloadCountdown((drawKey) => drawKey + 1);
      // console.log('drawFinished');
    }
  }

  useEffect(() => {
    setReloadKey((prevKey) => prevKey + 1);
  }, []);

  // useEffect(() => {
  //   setReloadKey((prevKey) => prevKey + 1);
  // }, [reloadCountdown]);

  useEffect(() => {
    if (buyingTokenAddress) {
      // console.log('BuyingTokenAddress', buyingTokenAddress);
      const fetchData = async () => {
        try {
          const data = await readContract({
            address: buyingTokenAddress,
            abi: commonConfig.commonERC20ContractABI,
            functionName: 'balanceOf',
            args: [configs.superlottoContractAddress]
          });
          if (data) {
            const poolBalance = parseFloat(utils.formatUnits(data.toString()))
            setBalanceOfPool(poolBalance)
            let jackpotValue = poolBalance * (55 / 100)
            setJackpotValue1(jackpotValue)
          }
        } catch (error) {
          console.log('Error fetching jackPotValue:', error);
        }
      };

      fetchData();
    }
  }, [buyingTokenAddress, reloadKey])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await readContract({
  //         address: configs.superlottoContractAddress,
  //         abi: configs.superlottoContractABI,
  //         functionName: 'jackPotValue',
  //       });
  //       if (data) {
  //         setJackpotValue1(utils.formatUnits(data.toString()));
  //       }
  //     } catch (error) {
  //       console.log('Error fetching jackPotValue:', error);
  //     }
  //   };

  //   fetchData();
  // }, [reloadKey]);



  const { data: roundId } = useContractRead({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'roundId',
  });

  useEffect(() => {
    if (roundId) {
      setRoundIdValue(roundId.toString());
    }
  }, [roundId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formattedJPValue = jackPotValue1 !== '' ? parseFloat(jackPotValue1).toFixed(2) : '';

  const fixDraw = 30;
  const lastRoundId = roundIdValue - 1;

  const fetchLastDrawWinners = async () => {
    try {
      const response = await axios.get(`https://ws5erymmd8.us-east-1.awsapprunner.com/api/winners/${lastRoundId}`);
      if (response.status === 200) {
        const data = response.data;
        setWinnerDetails(data);
        // console.log('winnerDetails', winnerDetails);
        setLastDrawWinners(data.length);
      } else {
        console.error('Failed to fetch last draw winners:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching last draw winners:', error);
    }
  };

  useEffect(() => {
    fetchLastDrawWinners();
  }, []);

  return (
    <div>
      <div className='page-header'>
        <Row className='footer mt-5 mb-5'>
          {/* <HeaderSection jackPotValue={formattedJPValue} /> */}
        </Row>
        {/* <div className='mt-5 mb-5 ' /> */}
        <Row className='hide-on-mobile mt-5'>
          <Col className='mt-5' xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <JackpotValueCard jackPotValue={formattedJPValue} />
          </Col>
          <Col className='mt-5' xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <JackpotCountdownCard buyingTokenAddress={buyingTokenAddress} drawFinished={handleDrawFinished} onBuyTicketsSuccess={handleBuyTicketsSuccess} key={reloadKey} roundIdNo={roundIdValue} />
          </Col>
        </Row>
        <Row className='hide-on-pc'>
          <Col xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <JackpotValueCard jackPotValue={formattedJPValue} />
          </Col>
          <Col xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <JackpotCountdownCard buyingTokenAddress={buyingTokenAddress} drawFinished={handleDrawFinished} onBuyTicketsSuccess={handleBuyTicketsSuccess} key={reloadKey || reloadCountdown} roundIdNo={roundIdValue} />
          </Col>
        </Row>
        <Row>
          <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
            <LastWinningNumbers roundIdNo={roundIdValue} winnerDetails={winnerDetails} lastDrawWinners={lastDrawWinners} />
          </Col>
        </Row>
        <Row key={reloadKey}>
          <Col xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <TicketsCard winnerDetails={winnerDetails} roundIdNo={roundIdValue} />
          </Col>
          <Col xxl='6' xl='6' lg='6' md='6' sm='12' xs='12'>
            <ResultTableCard jackPotValue={jackPotValue1} />
          </Col>
        </Row>
        <Row>
          <Col xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <YourTickets key={reloadKey} roundIdNo={roundIdValue} />
          </Col>
          <Col xxl='6' xl='6' lg='6' md='12' sm='12' xs='12'>
            <WinnerDraw roundIdNo={roundIdValue} />
          </Col>
        </Row>
        <Row className=' mb-5'>
          <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
            <TicketsDrawPrizes />
          </Col>
        </Row>
        {/* <Row className='mb-4 mt-3'>
          <Col xxl='6' xl='6' lg='6' md='6' sm='12' xs='12'>
            <ResultTableCard jackPotValue={jackPotValue1} />
          </Col>
          <Col xxl='6' xl='6' lg='6' md='6' sm='12' xs='12'>
            <PlayersInsight />
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default TokenListPage;
