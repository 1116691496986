import React, { useEffect, useState } from 'react';
import { configs } from '../../../../Blockchain/web3.config';
import { useContractRead } from 'wagmi';
import './styles.css';

const calculateTimeLeft = (targetTime) => {
    const difference = +new Date(targetTime * 1000) - +new Date();

    if (difference <= 0) {
        // The target time has passed
        return {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00',
        };
    }

    const days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
    const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
    const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
    const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');

    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};

const DigitComponent = ({ value }) => {
    const digits = value.toString().split('');
    const [firstDigit, secondDigit] = digits;

    return (
        <>
            <span className="value">{firstDigit}</span>
            <span className="value">{secondDigit}</span>
        </>
    );
};

const CountdownComponent = (props) => {
    const { data: nextDrawTime } = useContractRead({
        address: configs.superlottoContractAddress,
        abi: configs.superlottoContractABI,
        functionName: 'nextDrawTime'
    });

    const [days, setDays] = useState('00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');
    const [countdownFinished, setCountdownFinished] = useState(false); // New state variable

    useEffect(() => {
        if (nextDrawTime?.toString()) {
            const nextDrawTimeValue = nextDrawTime.toString();
            const calculatedTimeLeft = calculateTimeLeft(nextDrawTimeValue);
            updateCountdown(calculatedTimeLeft);
        }

        const countdownTimer = setInterval(() => {
            if (nextDrawTime?.toString()) {
                const nextDrawTimeValue = nextDrawTime.toString();
                const calculatedTimeLeft = calculateTimeLeft(nextDrawTimeValue);
                updateCountdown(calculatedTimeLeft);

                if (
                    calculatedTimeLeft.days === '00' &&
                    calculatedTimeLeft.hours === '00' &&
                    calculatedTimeLeft.minutes === '00' &&
                    calculatedTimeLeft.seconds === '00'
                ) {
                    setCountdownFinished(true);
                }
            }
        }, 1000);

        return () => {
            clearInterval(countdownTimer);
        };
    }, [nextDrawTime?.toString(), countdownFinished]);

    const updateCountdown = (timeLeft) => {
        setDays(timeLeft.days);
        setHours(timeLeft.hours);
        setMinutes(timeLeft.minutes);
        setSeconds(timeLeft.seconds);
    };

    const reloadCountdown = () => {
        setCountdownFinished(false);
        // props.drawFinished('drawFinished');
        // props.onBuyTicketsSuccess("Buy");
        // Perform any necessary actions to reload the countdown here
    };

    useEffect(() => {
        if (countdownFinished) {
            reloadCountdown();
        }
    }, [countdownFinished]);

    return (
        <div className="counter">
            <div className="counter-item">
                <DigitComponent value={days} />
                <span className="label1">Days</span>
            </div>
            <div className="counter-item">
                <DigitComponent value={hours} />
                <span className="label1">Hours</span>
            </div>

            <div className="counter-item">
                <DigitComponent value={minutes} />
                <span className="label2">Minutes</span>
            </div>

            <div className="counter-item">
                <DigitComponent value={seconds} />
                <span className="label3">Seconds</span>
            </div>
        </div>
    );
};

export default CountdownComponent;
