import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { configs } from '../../Blockchain/web3.config'
import ReadFunctions from './ReadFunction'
import WriteFunctions from './WriteFunction'

export const getFathomTokenContractABIFunctions = () => {
    let readFunctionsList = []
    let writeFunctionList = []
    const fathomTokenContractABI = JSON.parse(configs.superlottoContractABI)
    readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
    writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
    return {
        readFunctionsList,
        writeFunctionList
    }

}

function Admin() {
    const { TabPane } = Tabs

    const fetchFathomTokenContractFunctions = () => {
        const response = getFathomTokenContractABIFunctions();
        setFathomTokenReadFunctions(response.readFunctionsList);
        setFathomTokenWriteFunctions(response.writeFunctionList);
    };

    // Separate the read and write functions into two different states
    const [fathomTokenReadFunctions, setFathomTokenReadFunctions] = useState([]);
    const [fathomTokenWriteFunctions, setFathomTokenWriteFunctions] = useState([]);

    useEffect(() => {
        fetchFathomTokenContractFunctions();
        // console.log('writeFunctionList :', fathomTokenWriteFunctions);
        // console.log('readFunctionsList :', fathomTokenReadFunctions);
    }, []);

    return (
        <div className='mx-auto mt-5'>
            <div className='admin-tab-align'>
                <div className='mt-5 mb-5'>
                    <Tabs defaultActiveKey="1" type="card" size="large" fixed>
                        {/* Render the ReadFunctions component in the "Read Functions" tab */}
                        <TabPane tab="Read Functions" key="1">
                            {fathomTokenReadFunctions.map((func, index) => (
                                <ReadFunctions
                                    key={`readFunc${index}`}
                                    contractABI={configs.superlottoContractABI}
                                    contractAddress={configs.superlottoContractAddress}
                                    readFunctions={[func]}
                                />
                            ))}
                        </TabPane>
                        {/* Render the WriteFunctions component in the "Write Functions" tab */}
                        <TabPane tab="Write Functions" key="2">
                            {fathomTokenWriteFunctions.map((func, index) => (
                                <WriteFunctions
                                    key={`writeFunc${index}`}
                                    contractABI={configs.superlottoContractABI}
                                    contractAddress={configs.superlottoContractAddress}
                                    writeFunctions={[func]}
                                />
                            ))}
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Admin;