import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { ethers } from 'ethers';

const columns = [
    {
        title: 'Winners',
        dataIndex: 'result',
        width: 150,
    },
    {
        title: 'Amount',
        dataIndex: 'prize',
        width: 150,
    },
];

function WinnerResultsTable(props) {
    const jackPotValue = props.jackpotValue;
    const winnerDetails = props.winnerDetails;

    const data = [];
    for (let i = 0; i < winnerDetails.length; i++) {
        const winner = winnerDetails[i];
        const prize = ethers.utils.formatUnits(Number(winner.winningAmount).toFixed(0));

        let result;
        if (window.innerWidth >= 768) {
            // Web view
            result = (
                <div>
                    {i + 1}. <span>{winner.owner}</span>
                </div>
            );

        } else {
            // Mobile view
            result = (
                <div>
                    {i + 1}. <span>{winner.owner.substring(0, 5)}...</span>
                    <span>{winner.owner.slice(-5)}</span>
                </div>
            );

        }

        data.push({
            key: i,
            result: result,
            prize: `${prize} USDT`,
        });
    }

    return (
        <div>
            <Table
                rowClassName={(record, index) => (index % 2 === 1 ? 'table-row-light' : 'table-row-dark')}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={false}
            />
        </div>
    );
}

export default WinnerResultsTable;