import { Button, InputNumber, message, notification } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { useContractWrite, useContractRead, usePrepareContractWrite } from "wagmi";
import { configs, buyingTokenConfigs } from '../../../Blockchain/web3.config';
import { Col, Row } from 'reactstrap';
import './PlayNow.css';
import { utils } from 'ethers';
import imageUrl from '../../../images/ticket-bg.png'
import imageUrl2 from '../../../images/ticket-bg.svg'
import { CloseOutlined, DeleteFilled } from '@ant-design/icons';

function EditNumbers(props) {
  const ticketPrize = props.ticketPrize;
  const [ticketPrice, setTicketPrice] = useState(0);
  const ticketPrizeValue = utils.parseEther(ticketPrice.toString());
  const [ticketNumbers, setTicketNumbers] = useState([]);
  const [initialNumbers, setInitialNumbers] = useState([]);
  const [buyingTokenAddress, setBuyingTokenAddress] = useState('');
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isTokenApproved, setIsTokenApproved] = useState(false);
  const [haveDuplicate, setHaveDuplicate] = useState(false)
  // const [duplicatesErrorMessage, setDuplicatesErrorMessage] = useState('')
  // const [isCanProceed, setIsCanProceed] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    handleAddTicket();
    // ticketCost();
  }, []);

  const { config: approveTokenWriteConfigs } = usePrepareContractWrite({
    address: buyingTokenAddress,
    abi: buyingTokenConfigs.superlottoBuyingTokenContractABI,
    functionName: 'approve',
    args: [
      configs.superlottoContractAddress,
      ticketPrizeValue,
    ]
  });

  const { writeAsync: approveTokenSync, error: tokenApprovalErrors } = useContractWrite(approveTokenWriteConfigs);

  const { data: buyingToken } = useContractRead({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'buyingToken'
  });

  useEffect(() => {
    if (buyingToken) {
      setBuyingTokenAddress(buyingToken.toString());
    }
  }, [buyingToken]);

  const approveContract = async () => {
    try {
      setIsApproveLoading(true);
      const txReceipt = approveTokenSync && await approveTokenSync();
      const result = txReceipt && await txReceipt.wait();
      // message.success("Token has been approved");
      if (result) {
        notification['success']({
          message: 'Success',
          description: 'Approval Success!',
        });
        setIsTokenApproved(true);
        setIsApproveLoading(false);
      }
      // console.log('result', result);
    } catch (error) {
      message.error(error)
      message.error("Ticket approval has failed!", error);
      setIsApproveLoading(false);
      setIsTokenApproved(false);
    } finally {
      setIsApproveLoading(false);
    }
  };

  const buyTicket = async () => {
    try {
      setIsApproveLoading(true);
      let ticketBuyReceipt, ticketBuyResult;
      ticketBuyReceipt = buyTicketProcessing && await buyTicketProcessing();
      ticketBuyResult = await ticketBuyReceipt?.wait();
      if (ticketBuyResult && !buyTicketErrors) {
        // message.success("Buy tickets successfully!");
        notification['success']({

          message: 'Success',
          description: 'Buy Tickets Successfully!',
        });
      }
      props.onBuyTicketsSuccess("Buy");
      setIsApproveLoading(false);
    } catch (error) {
      message.error("Buy ticket has failed!", error);
      message.error(error)
      setIsTokenApproved(false);
    } finally {
      setIsApproveLoading(false);
    }
  };


  const handleChangeTicketNumber = (ticketIndex, numberIndex, value) => {
    if (isNaN(value) || value < 0 || value > 30) {
      message.error('Please enter a valid number between 0 and 30');
      return;
    }

    const updatedTicketNumbers = [...ticketNumbers];
    updatedTicketNumbers[ticketIndex].value[numberIndex].number = value;

    setTicketNumbers(updatedTicketNumbers);
    const editedNumbers = updatedTicketNumbers.map(({ value }) => value.map(({ number }) => number));
    setInitialNumbers(editedNumbers);

    const ticketNumberArray = updatedTicketNumbers[ticketIndex].value.map(({ number }) => number);
    if (hasDuplicates(ticketNumberArray)) {
      // setDuplicatesErrorMessage('Duplicate numbers are not allowed within a ticket')
      message.error('Duplicate numbers are not allowed within a ticket');
      setHaveDuplicate(true);
    } else {
      setHaveDuplicate(false);
    }
  };

  const toggleEditable = (ticketIndex, numberIndex) => {
    const updatedTicketNumbers = [...ticketNumbers];
    const ticketNumberArray = updatedTicketNumbers[ticketIndex].value.map(({ number }) => number);

    ticketNumberArray.forEach((number, index) => {
      if (index === numberIndex) {
        updatedTicketNumbers[ticketIndex].value[index].editable = !updatedTicketNumbers[ticketIndex].value[index].editable;
      } else {
        updatedTicketNumbers[ticketIndex].value[index].editable = false;
      }
    });

    setTicketNumbers(updatedTicketNumbers);
    setInitialNumbers(updatedTicketNumbers.map(({ value }) => value.map(({ number }) => number)));

    if (hasDuplicates(ticketNumberArray)) {
      // message.error('Duplicate numbers are not allowed within a ticket');

      setHaveDuplicate(true);
      return;
    }
  };

  const hasDuplicates = (array) => {
    const uniqueSet = new Set(array);
    return uniqueSet.size !== array.length;
  };

  const inputRef = useRef(null);

  const generateRandomNumbers = () => {
    const numbers = new Set();

    while (numbers.size < 6) {
      const randomNumber = Math.floor(Math.random() * 31);
      numbers.add(randomNumber);
    }

    return Array.from(numbers);
  };

  const handleAddTicket = () => {
    if (ticketNumbers.length < 10) {
      const newTicket = {
        value: generateRandomNumbers().map((number) => ({ number, editable: false })),
      };
      setTicketNumbers([...ticketNumbers, newTicket]);
      setInitialNumbers([...initialNumbers, newTicket.value.map(({ number }) => number)]);
      // console.log('setTicketNumbers([...', ticketNumbers);
      // console.log('setInitialNumbers', initialNumbers);
    } else {
      // Handle maximum limit reached, such as displaying an error message
      message.warning('Maximum limit reached. Cannot add more tickets.');
    }
  };

  const { writeAsync: buyTicketProcessing, error: buyTicketErrors } = useContractWrite({
    address: configs.superlottoContractAddress,
    abi: configs.superlottoContractABI,
    functionName: 'buyCustomizedTicket',
    args: [initialNumbers],
  });

  useEffect(() => {
    const ticketCost = () => {
      const price = parseFloat(ticketPrize) * parseFloat(ticketNumbers.length);
      if (!isNaN(price)) {
        setTicketPrice(price);
      } else {
        setTicketPrice(0);
      }
    };

    ticketCost(); // Initial calculation when component mounts

    return () => {
      ticketCost();
    };
  }, [ticketNumbers, ticketPrize]);

  const removeTicket = (ticketIndex) => {
    const updatedTicketNumbers = [...ticketNumbers];
    updatedTicketNumbers.splice(ticketIndex, 1); // Remove the ticket at the specified index
    setTicketNumbers(updatedTicketNumbers);
    setInitialNumbers(updatedTicketNumbers.map(({ value }) => value.map(({ number }) => number)));
  };

  useEffect(() => {
    if (ticketNumbers.length === 0) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [ticketNumbers])

  return (
    <div>
      <div className="claim-card-content">
        <h6 className="claim-card-headline">Your Ticket</h6>
      </div>
      <div className={ticketNumbers.length > 1 ? "list-card-ticket-edit" : "list-card-ticket-edit2"}>
        {/* <div className="list-card-ticket-edit"> */}
        {ticketNumbers.length >= 0 ? (
          <div className=''>
            <p className='mx-auto'>(You can choose numbers between 0 and 30)</p>
          </div>) : ''}
        <div className="scrollable-list hide-on-mobile" style={{ height: ticketNumbers.length > 1 ? '280px' : '150px', overflowY: 'scroll' }}>
          {ticketNumbers.length === 0 ? (
            <div className='mx-auto'>
              <p className='mt-5 align-choose-number mx-auto'>
                (You can purchase a maximum of 10 editable tickets at a time)
              </p>
            </div>
          ) : (

            ticketNumbers.map((ticket, index) => (
              <li key={index} className="button-align-center mx-auto">
                <div className='ticket-list-background-play-now hide-on-mobile' style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                  <Row className="row-align-tickets-random">
                    <Row >
                      <Col className="number-edits-message-close2">
                        <span onClick={() => removeTicket(index)}><DeleteFilled /></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="number-edits-message-close1" >
                        <span className='draw-color'>Ticket {index + 1}</span>
                      </Col>
                    </Row>
                    <div className='mx-auto row-width'>
                      <Row className='mt-2'>
                        {ticket.value.map((numberObj, numberIndex) => {
                          const duplicateNumbers = [];
                          const duplicateIndexes = []
                          const numberArray = ticket.value.map(({ number }) => number);
                          numberArray.forEach((number, index) => {
                            if (numberArray.indexOf(number) !== index && !duplicateIndexes.includes(index)) {
                              duplicateIndexes.push(index);
                              duplicateNumbers.push(number);
                            }
                          });

                          return (
                            <div
                              key={numberIndex}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '45px',
                                width: '45px',
                                margin: '5px',
                                padding: '5px',
                                cursor: 'pointer',
                                background: duplicateNumbers.includes(numberObj.number) ? 'red' : '#10183C',
                                color: numberObj.editable ? '#ffffff' : '#ffffff',
                                fontWeight: '500',
                                borderRadius: '50%',
                              }}
                              onClick={() => toggleEditable(index, numberIndex)}
                            >
                              {numberObj.editable ? (
                                <InputNumber
                                  className="no-arrow-input"
                                  size="small"
                                  ref={inputRef}
                                  style={{ backgroundColor: 'none', borderColor: '#184D67', border: 'none', color: '#ffffff' }}
                                  value={numberObj.number}
                                  onChange={(value) => handleChangeTicketNumber(index, numberIndex, value)}
                                  onBlur={() => toggleEditable(index, numberIndex)}
                                  onPressEnter={() => toggleEditable(index, numberIndex)}
                                  autoFocus={numberObj.editable}
                                  min={0}
                                  max={30}
                                />
                              ) : (
                                <span>{numberObj.number}</span>
                              )}
                            </div>
                          );
                        })}
                      </Row>
                    </div>
                  </Row>
                </div>
              </li>
            ))
          )}
        </div>
        {/* {
          haveDuplicate ?
            <div className='error-messages mb-1'>
              {duplicatesErrorMessage}
            </div> : <></>
        } */}
        <div className="scrollable-list mt-1 hide-on-pc" style={{ height: '200px', overflowY: 'scroll', }}>
          {ticketNumbers.length === 0 ? (
            <div className='mx-auto'>
              <p className='mt-5 align-choose-number mx-auto'>
                (You can buy a maximum of 10 editable tickets at once)
              </p>
            </div>
          ) : (
            ticketNumbers.map((ticket, index) => (
              <li key={index} className="button-align-center mx-auto">
                <div className='ticket-list-background-play-now ticket-alignment-mobile-edit hide-on-pc' style={{ backgroundImage: `url(${imageUrl2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                  <Row className='align-avatar-row1'>
                    <Row className='mt-4'>
                      <Col className='number-edits-message-close3' xxl='6' xl='6' lg='6' md='6' sm='6' xs='6'>
                        <span className='draw-color-edit'>Ticket {index + 1}</span>
                      </Col>
                      <Col className='number-edits-message-close2' xxl='6' xl='6' lg='6' md='6' sm='6' xs='6'>
                        <span className='draw-color-edit1' onClick={() => removeTicket(index)}><DeleteFilled /></span>
                      </Col>
                    </Row>
                    {ticket.value.map((numberObj, numberIndex) => {
                      const duplicateNumbers = [];
                      const duplicateIndexes = []
                      const numberArray = ticket.value.map(({ number }) => number);
                      numberArray.forEach((number, index) => {
                        if (numberArray.indexOf(number) !== index && !duplicateIndexes.includes(index)) {
                          duplicateIndexes.push(index);
                          duplicateNumbers.push(number);

                        }
                      });
                      return (
                        <div className='button-align-center mt-2'
                          key={numberIndex}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '32px',
                            width: '32px',
                            padding: '1px',
                            margin: '2px',
                            cursor: 'pointer',
                            background: duplicateNumbers.includes(numberObj.number) ? 'red' : '#10183C',
                            color: numberObj.editable ? '#ffffff' : '#ffffff',
                            fontWeight: '500',
                            borderRadius: '50%',
                          }}
                          onClick={() => toggleEditable(index, numberIndex)}
                        >
                          {numberObj.editable ? (
                            <InputNumber
                              className="no-arrow-input"
                              size="small"
                              ref={inputRef}
                              style={{ backgroundColor: 'none', borderColor: '#184D67', border: 'none', color: '#ffffff' }}
                              value={numberObj.number}
                              onChange={(value) => handleChangeTicketNumber(index, numberIndex, value)}
                              onBlur={() => toggleEditable(index, numberIndex)}
                              onPressEnter={() => toggleEditable(index, numberIndex)}
                              autoFocus={numberObj.editable}
                              min={0}
                              max={30}
                            />
                          ) : (
                            <span>{numberObj.number}</span>
                          )}
                        </div>
                      )
                    })}
                  </Row>
                </div>
              </li>
            ))
          )}
        </div>
      </div>
      <div className='mt-4 claim-card-content'>
        <h5 className='sub-text'>Total amount:</h5>
        <h5 className='sub-text'>{ticketPrice} USDT</h5>
      </div>
      <div className="button-align-center mt-1">
        <div className='button-align-center'>
          <Button
            className='m-2'
            type='primary'
            onClick={handleAddTicket}
          >
            + Add Tickets
          </Button>
        </div>
        <div className='button-align-center'>
          {!isTokenApproved ? (
            <Button
              className='m-2'
              type='primary'
              onClick={approveContract}
              loading={isApproveLoading}
              disabled={disabledButton}
            >
              Approve
            </Button>
          ) : (
            <Button
              className='m-2'
              type='primary'
              onClick={buyTicket}
              loading={isApproveLoading}
            >
              Buy Tickets
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditNumbers;
