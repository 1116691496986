import React from 'react'
import { Card } from 'antd'
import { Col, Row } from 'reactstrap'
import Wind1 from '../../../images/windimage/Group1.svg'
import Wind2 from '../../../images/windimage/Group2.svg'
import Wind3 from '../../../images/windimage/Group3.svg'
import './WinnersTickets.css'

function TicketsDrawPrizes() {
    return (
        <>
            <Card className='fathom-finance-card mt-4 mb-2 text-center new-head' >
                <Row className='winning-numbers-set'>
                    <Col className='center-items-set mt-3 mb-2' xxl="4" xl="4" lg="4" md="4" sm="12" xs="12">
                        <div>
                            <div className='center-items-set mb-2'>
                                <img style={{ height: '75px', width: '100px' }} src={Wind2} alt='Service Icon' />
                            </div>
                            {/* <h5 className='center-items-set mt-3'>
                                Buy Tickets
                            </h5> */}
                            <h6 className='center-pragraph-prize mt-3'>
                                Buy your ticket for just 1.00 USDT and choose your ticket numbers.
                            </h6>
                        </div>
                    </Col>
                    <Col className='center-items-set mt-3 mb-2' xxl="4" xl="4" lg="4" md="4" sm="12" xs="12">
                        <div>
                            <div className='center-items-set mb-2'>
                                <img style={{ height: '95px', width: '70px' }} src={Wind1} alt='Service Icon' />
                            </div>
                            {/* <h5 className='center-items-set mt-3'>
                                Wait for the Draw
                            </h5> */}
                            <h6 className='center-pragraph-prize mt-3'>
                                Weekly drawing occurs based on timer above, Drawing will occur when the countdown reaches 0</h6>
                        </div>
                    </Col>
                    <Col className='center-items-set mt-3 mb-2' xxl="4" xl="4" lg="4" md="4" sm="12" xs="12">
                        <div>
                            <div className='center-items-set mb-2'>
                                <img style={{ height: '70px', width: '106px' }} src={Wind3} alt='Service Icon' />
                            </div>
                            {/* <h5 className='center-items-set mt-3'>
                                Check for Prizes
                            </h5> */}
                            <h6 className='center-pragraph-prize mt-3'>
                                Once the round’s over, check to see if you’ve won!
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default TicketsDrawPrizes