import React, { useEffect, useState } from 'react';
import { configs } from '../../../Blockchain/web3.config';
import { useAccount } from 'wagmi';
import { Avatar, Card, Spin } from 'antd';
import './WinnersTickets.css';
import { Col, Row } from 'reactstrap';
import { readContract } from '@wagmi/core';
import imageUrl from '../../../images/ticket-bg.png'
import imageUrl2 from '../../../images/ticket-bg.svg'
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 16,
        }}
        spin
    />
);

const YourTickets = (props) => {
    const [allTicketsValue, setAllTicketsValue] = useState([]);
    const [ticketNumbers, setTicketNumbers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { address, isConnected } = useAccount();
    const userAddress = address;
    const roundIdValue = props.roundIdNo;

    useEffect(() => {
        const fetchUserTickets = async () => {
            setIsLoading(true);
            try {
                const data = await readContract({
                    address: configs.superlottoContractAddress,
                    abi: configs.superlottoContractABI,
                    functionName: 'getUserTickets',
                    args: [roundIdValue, userAddress],
                });

                if (data !== null) {
                    const userTicketIds = data.map((item) => parseFloat(item.toString()));
                    setAllTicketsValue(userTicketIds);
                }
            } catch (error) {
                console.error('Error fetching user tickets:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isConnected) {
            fetchUserTickets();
        }
    }, [isConnected, roundIdValue, userAddress]);

    useEffect(() => {
        const fetchTicketNumbers = async () => {
            setIsLoading(true);
            const tickets = [];
            for (let i = 0; i < allTicketsValue.length; i++) {
                try {
                    const ticketNumber = allTicketsValue[i];
                    const data = await readContract({
                        address: configs.superlottoContractAddress,
                        abi: configs.superlottoContractABI,
                        functionName: 'getTicketNumbers',
                        args: [ticketNumber],
                    });

                    if (data !== null) {
                        tickets.push({ number: ticketNumber, value: data.toString() });
                    }
                } catch (error) {
                    console.error('Error fetching ticket numbers:', error);
                }
            }
            setTicketNumbers(tickets);
            setIsLoading(false);
        };

        if (allTicketsValue.length > 0 && isConnected) {
            fetchTicketNumbers();
        }
    }, [allTicketsValue, isConnected, roundIdValue]);

    const allTicket = allTicketsValue.length;

    const isMobileView = window.innerWidth <= 768;

    return (
        <Card className='fathom-finance-card your-ticket-card mt-4 mb-2 text-center'><div className="claim-card-content">
            <div className="your-tickets-headline">Your Tickets</div>
            <div className="your-tickets-headline2">Tickets:{ticketNumbers.length === 0 && isLoading ? <Spin className='allTicket-align2' indicator={antIcon} /> : <span className='allTicket-align' style={{ padding: '4px', background: '#334467' }}>{allTicket}</span>}</div>
        </div>
            <div className="bottom-line mt-3 mb-3" />
            <div className='mt-4 claim-card-headline'>
                {!isConnected ? (
                    <div className='list-card-ticket'>
                        <p className='align-your-tickets'>Please, connect your wallet to see your tickets.</p>
                    </div>
                ) : ticketNumbers.length === 0 && !isLoading ? (
                    <div className='list-card-ticket'>
                        <p className='align-your-tickets'>You have not bought any tickets yet.</p>
                    </div>
                ) : isLoading ? (
                    <Spin className='mt-5' />
                ) : (
                    <div
                        className='scrollable-list'
                        style={{ height: !isMobileView ? '280px' : '180px', overflowY: 'auto', paddingRight: '12px' }}>
                        <div className='list-card-ticket hide-on-mobile'>
                            {ticketNumbers.map((ticket, index) => (
                                <div key={index} className='ticket-list-background mx-auto' style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                    <Row className='winning-draw-numbers'>
                                        <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                            <div className='mt-4 draw-color'>Ticket {index + 1}</div>
                                        </Col>
                                        <div className='separate-botom-line mt-3' />
                                        <Col className='mt-2' xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                            {Array.isArray(ticket.value) ? (
                                                ticket.value.map((number, subIndex) => (
                                                    <Avatar
                                                        key={subIndex}
                                                        style={{
                                                            background: '#10183C',
                                                            color: '#ffffff',
                                                            fontWeight: '500',
                                                            margin: '2px',
                                                        }}
                                                        className='m-1'
                                                    >
                                                        {number}
                                                    </Avatar>
                                                ))
                                            ) : (
                                                ticket.value
                                                    .split(',')
                                                    .map((number, subIndex) => (
                                                        <Avatar
                                                            key={subIndex}
                                                            style={{
                                                                color: '#ffffff',
                                                                fontWeight: '500',
                                                                margin: '2px',
                                                                background: '#10183C',
                                                                // background: 'radial-gradient(50% 50% at 50% 50%, #183D99 0%, #00B0F2 100%)',
                                                            }}
                                                            className='m-1'
                                                        >
                                                            {number.trim()}
                                                        </Avatar>
                                                    ))
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <div className='list-card-ticket hide-on-pc'>
                            {ticketNumbers.map((ticket, index) => (
                                <div key={index} className='ticket-list-background ticket-alignment-mobile mx-auto' style={{ backgroundImage: `url(${imageUrl2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                    <Row className='winning-draw-numbers' >
                                        <Col xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                            <div className='draw-color'>Ticket {index + 1}</div>
                                        </Col>
                                        <div className='separate-botom-line mt-1' />
                                        <Col className='mt-1' xxl='12' xl='12' lg='12' md='12' sm='12' xs='12'>
                                            {Array.isArray(ticket.value) ? (
                                                ticket.value.map((number, subIndex) => (
                                                    <Avatar
                                                        key={subIndex}
                                                        size={25}
                                                        style={{
                                                            background: '#10183C',
                                                            color: '#ffffff',
                                                            fontWeight: '500',
                                                            margin: '2px',
                                                        }}
                                                        className='m-1'
                                                    >
                                                        {number}
                                                    </Avatar>
                                                ))
                                            ) : (
                                                ticket.value
                                                    .split(',')
                                                    .map((number, subIndex) => (
                                                        <Avatar
                                                            key={subIndex}
                                                            size={25}
                                                            style={{
                                                                color: '#ffffff',
                                                                fontWeight: '100',
                                                                fontSize: '12px',
                                                                margin: '2px',
                                                                background: '#10183C',
                                                                // background: 'radial-gradient(50% 50% at 50% 50%, #183D99 0%, #00B0F2 100%)',
                                                            }}
                                                            className='m-1'
                                                        >
                                                            {number.trim()}
                                                        </Avatar>
                                                    ))
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default YourTickets;
